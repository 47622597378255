import "./MapEntityComponents/OverlayEntity.css";

import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import L from "leaflet";

import { FunctionalDeviceInfoCard } from "../../../components/specialized/infoCards/DeviceInfoCard/FunctionalDeviceInfoCard";
import { GenerateUID, sleep } from "../../../utils/HelperFunctions";
import { MapComponent } from "../MapHelper";
import MapEntityBase from "./MapEntityBase";
import { GenerateRemappedNDVIImage } from "../../NDVIColorMapper/GenerateRemappedNDVIImage";

const EcoIcon = LocalFloristIcon;
export default class OverlayEntity extends MapEntityBase {
    static infoCardClass = FunctionalDeviceInfoCard;

    get date() {
        return this.model.date;
    }

    /**
     *
     * @return {Number[]}
     */
    get corner1() {
        return [this.topLeft.lat, this.topLeft.long];
    }

    /**
     *
     * @return {(Number)[]}
     */
    get corner2() {
        return [this.botRight.lat, this.botRight.long];
    }

    get lat() {
        return this.model.topRight.lat;
    }

    // eslint-disable-next-line class-methods-use-this
    set lat(v) {
        // pass
    }

    get long() {
        return this.model.topRight.long;
    }

    // eslint-disable-next-line class-methods-use-this
    set long(v) {
        // pass
    }

    /**
     * Coordinates of top left corner of image
     * @type {{lat: Number, lng: Number}}
     */
    get topLeft() {
        return this.model.topLeft;
    }

    /**
     * Width and height in degrees. long is width and lat is height if north is up
     * @type {{lng: number, lat: number}}
     */
    get dimensions() {
        return {
            lat: Math.abs(this.botRight.lat - this.topLeft.lat),
            lng: Math.abs(this.botRight.lng - this.topLeft.lng),
        };
    }

    /**
     * Bottom right corner of image
     * @return {{lat: Number, lng: Number}}
     */
    get botRight() {
        return this.model.botRight;
    }

    /**
     * @param {OverlayBase} Overlay
     * @param renderImmediately
     */
    constructor(Overlay, renderImmediately = true) {
        super(Overlay, GenerateUID("OVL"), false);
        this.icon = EcoIcon;
        if (renderImmediately) {
            this.renderOnMap();
        }
    }

    renderOnMap() {
        if (!this.renderState.onMap && !this.renderState.beingRendered && MapComponent) {
            this.renderState.beingRendered = true;
            const grayscaleImageURL = this.model.imageURL.replace("color.png", "gray.png").replace("color.webp", "gray.png");
            GenerateRemappedNDVIImage(grayscaleImageURL).then((newImageURL)=>{
                this.leafletElement = L.imageOverlay(newImageURL, [this.corner1, this.corner2], {
                    className: `${this.uid} overlayEntity overlayEntity--${this.model.type} ${this.DOMClassNames.join(
                        " ",
                    )}`,
                });
                this.leafletElement.addTo(MapComponent);
                const TryToLoadDOMElement = async () => {
                    for (let i = 0; i < 50; i++) {
                        await sleep(100 * Math.sqrt(i + 1));
                        const targetElement = document.getElementsByClassName(this.uid);
                        if (targetElement.length > 0) {
                            this.domElements.push(targetElement[0]);
                            this.renderState.onMap = true;
                            this.renderState.beingRendered = false;
                            return;
                        }
                    }
                    this.renderState.onMap = false;
                    this.renderState.beingRendered = false;
                };
                TryToLoadDOMElement();
            })



        }
    }

    unRender() {
        if (this.renderState.onMap && !this.renderState.beingRendered) {
            this.renderState.beingRendered = true;
            this.leafletElement.remove();
            this.domElements = this.domElements.filter((e) => document.body.contains(e));
            this.renderState.onMap = false;
            this.renderState.beingRendered = false;
        }
    }
}
