import { NDVIColorMap } from "./NDVIColorMap";

export function getNDVIColor(NDVIValue) {
    const components = getNDVIColorComponents(NDVIValue);
    return `rgba(${components[0]}, ${components[1]}, ${components[2]})`;
    // NDVIValue *= NDVIValue
    // if (NDVIValue) {
    //     const mapIndex = Math.min( NDVIColorMap.length - 1, NDVIColorMap.length - Math.round(NDVIValue * NDVIColorMap.length));
    //     //console.log(mapIndex, NDVIColorMap.length)
    //     const color = NDVIColorMap[mapIndex];
    //     const r = color[0] * 255;
    //     const g = color[1] * 255;
    //     const b = color[2] * 255;
    //     return `rgba(${r}, ${g}, ${b})`;
    // }
    // return `rgba(189, 189, 189)`;
}

export function getNDVIColorComponents(NDVIValue) {
    NDVIValue **= 1.5
    if (NDVIValue) {
        const mapIndex = Math.min( NDVIColorMap.length - 1, NDVIColorMap.length - Math.round(NDVIValue * NDVIColorMap.length));
       // console.log(mapIndex)
        const color = NDVIColorMap[mapIndex];
        const r = color[0] * 255;
        const g = color[1] * 255;
        const b = color[2] * 255;
        return [r, g, b];
    }
    return [189, 189, 189];
}
