import { getNDVIColorComponents } from "../../utils/GetNDVIColor";


export async function GenerateRemappedNDVIImage(imageSource: string) : Promise<string> {
    let resolve: (arg0: string) => void;
    let reject: (arg0: unknown) => void;

    const promise : Promise<string> = new Promise<string>((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    })

    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageSource;

    image.onload = ()=>{
        try{

            resolve(onImageLoad(image));
        }catch(e){
            reject(e);
        }
    }
    return promise;
}

function onImageLoad(image: HTMLImageElement) : string {
    const hiddenCanvas = document.createElement("canvas");
    hiddenCanvas.style.display = "none";
    document.body.appendChild(hiddenCanvas);
    const outputCanvas =document.createElement("canvas");
    outputCanvas.style.display = "none";
    document.body.appendChild(outputCanvas);
    const hiddenCtx = hiddenCanvas.getContext('2d');
    const outputCtx = outputCanvas.getContext('2d');

    if(!hiddenCtx){
        throw new Error("Error 2d context not supported in canvas")
    }
    if(!outputCtx){
        throw new Error("Error 2d context not supported in canvas")
    }
    hiddenCanvas.width = image.width;
    hiddenCanvas.height = image.height;
    hiddenCtx.drawImage(image, 0, 0, image.width, image.height);

    const imageData = hiddenCtx.getImageData(0, 0, image.width, image.height);
    const data = imageData.data;

    // Loop through each pixel
    for (let i = 0; i < data.length; i += 4) {
        const grayscaleValue = data[i]; // Since it's grayscale, R, G, and B are equal
        const ndviValue =  (grayscaleValue / 256); // Convert 0-255 grayscale to -1 to 1 NDVI

        // Map NDVI to color
        const color = getNDVIColorComponents(ndviValue);

        // Set new color in image data
        data[i] = color[0];     // Red
        data[i + 1] = color[1]; // Green
        data[i + 2] = color[2]; // Blue
        // Alpha remains unchanged
    }
    outputCanvas.width = image.width;
    outputCanvas.height = image.height;
    outputCtx.putImageData(imageData, 0, 0);
    return outputCanvas.toDataURL();

}